// Fullscreen Modal

.modal.fullscreen {
  padding: 0 !important; // override inline padding-right added from js
}
.modal.fullscreen .modal-dialog {
  width: 100%;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal.fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal.fullscreen .modal-body {
  overflow-y: auto;
}
