.video-chapter-container {
  overflow-y: auto;
  overscroll-behavior: contain;
  display: none;

  @media (max-width: 576px) {
    display: none;
  }

  .video-chapters {
    border: none;
    border-bottom: 2px solid transparent;
    padding-left: 0.25rem !important  ;
    padding-right: 0.25rem !important ;
    color: $camp-emerald;
  }

  .video-chapters:hover {
    border-bottom: 2px solid white;
    color: $camp-emerald-hover;
  }
}
