// copied styles from layout-style.scss (modified)

#camp-navbar.bg-navbar-theme {
  #first-nav {
    justify-content: center;
  }

  &.hero-visible {
    #first-nav {
      justify-content: start;
    }

    .hide-with-hero {
      display: none;
    }
  }

  .navbar-nav > li {
    margin-left: 5px;
    margin-right: 5px;
  }
  .navbar-nav .nav-link {
    color: #fff;
  }
  .navbar-nav .nav-link:hover {
    color: #e5bd3f;
  }

  @media (min-width: 1200px) {
    .navbar-nav .nav-link {
      padding: 0;
      margin: 0.5rem;
      border-bottom: 2px solid transparent;
    }
    .navbar-nav .nav-link:hover {
      border-bottom: 2px solid $camp-gold;
      color: #fff;
    }
    .navbar-nav .active > .nav-link {
      border-bottom: 2px solid $camp-gold;
      color: #fff;
    }
  }

  .dropdown-item:not(.disabled) {
    &.active, &:active {
      background-color: transparent;
      color: inherit;
      position: relative;

      &:not(.menu-search):before {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 0.75rem;
        top: 0.5rem;
        bottom: 0.5rem;
        border: 2px solid;
        border-width: 0 0 0 2px;
      }
    }

    &:hover {
      &:not(.menu-search) {
        background-color: rgba(24, 28, 33, 0.03);
      }

      &.menu-search {
        background-color: transparent;
      }
    }
  }

  /* Search button in navbar */
  .navbar-search-collapse {
    position: fixed;
    top: 0.75rem;

    input {
      width: 400px;
    }

    &.collapsing {
      width: 0;
      height: auto;
      transition: width 0.35s ease;
    }
  }

  /* icon dropdowns */
  .dropdown-menu.with-icons {
    .dropdown-item {
      i {
        width: 2em;
        text-align: center;
        line-height: inherit;
        position: absolute;
      }
      div.label {
        margin-left: 2em;
      }
    }
  }
}

.impersonating #camp-navbar.bg-navbar-theme .navbar-search-collapse {
  top: calc(0.75rem + $camp-impersonating-bar-height);
}

@media screen and (max-width: 767px) { // bootstrap < medium (xs and sm) breakpoint
  .container, .container-fluid {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .page-header {
    margin-left: 0.5rem;
  }
}

nav.layout-footer.footer.bg-footer-theme .container-fluid {
  background: #fff;
  padding-top: 8px;
}

// end copied styles from layout-style.scss

#main-content {
  padding-top: $camp-top-navbar-height;
}

.impersonating #main-content {
  padding-top: calc($camp-top-navbar-height + $camp-impersonating-bar-height);
}

.tabbar .tab {
  color: #a3a4a6;
  padding-bottom: 0.5rem;
  margin-left: 1rem;
  text-align: end;

  @media screen and (min-width: 768px) { // bootstrap >= medium (xs and sm) breakpoint
    font-size: 120%;
  }
}

.tabbar .tab:hover {
  border-bottom: 2px solid $camp-gold;
}

.tabbar .active {
  border-bottom: 2px solid $camp-gold;
  color: #fff;
}

.footer-link {
  cursor:pointer;
  text-decoration: underline;
}
