
.camp-light .list-group-item.active {
  &, &:active, &:focus {
    border-color: #daeafb;
    background-color: #daeafb;
    color: #113559;
  }
}

.avatars {
  display: inline-flex;
  flex-direction: row-reverse;
  width: 80px;
  justify-content: center;
  * {
      position: relative;
      border: 2px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      width: 100px;
  }
  img {
    width: 100%;
    display: block;
  }
  *:not(:last-child) {
    margin-left: -7px;
  }
}
