.tributable {
  border-width: 0;
  outline: none;
  cursor: text;
  font-size: 15px;
  padding: 5px;
  margin-block-start: 0;
  margin-block-end: 0;
  word-break: break-word;
  min-height: 38px;
}
.tributable:empty:before {
  content: attr(placeholder);
  display: block;
  color: #ccc;
  padding: 2px;
}
.tribute-container {
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  display: block;
  z-index: 999999;
}
.tribute-container ul {
  margin: 0;
  margin-top: 2px;
  padding: 0;
  list-style: none;
  background: #fff;
}
.tribute-container li {
  padding: 5px 5px;
  cursor: pointer;
}
.tribute-container li.highlight {
  background: #ddd;
}
.tribute-container li span {
  font-weight: bold;
}
.tribute-container li.no-match {
  cursor: default;
}
.tribute-container .menu-highlighted {
  font-weight: bold;
}
