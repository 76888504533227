/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_tree app/assets/stylesheets
 *= require dropzone/dropzone
 *= require_self
 */

$fa-font-path: '~@fortawesome/fontawesome-pro/webfonts';
@import '@fortawesome/fontawesome-pro/scss/fontawesome';
@import '@fortawesome/fontawesome-pro/scss/regular';
@import '@fortawesome/fontawesome-pro/scss/solid';
@import '@fortawesome/fontawesome-pro/scss/brands';
@import 'fonts';

$camp-top-navbar-height: 69px;
$camp-impersonating-bar-height: 39px;

@import "vendor/assets/stylesheets/_custom-variables/appwork-campv2";

@import 'layout-style-v2';
@import 'list-group.custom';
@import 'search';
@import 'mention';
@import 'custom-bootstrap/index-v2';
@import 'wistia-helper-v2';
@import 'quiz-v2';
@import '../javascript/appwork/main';


.impersonating #navbar-container {
  top: $camp-impersonating-bar-height;
}

#content {
  padding-top: 10px;
}

textarea {
  width: 100%;
}

.invisible {
  visibility: hidden;
}

.actions {
  padding: 15px 0;
}

.drag-handle {
  cursor: grab;
}

.text-med {
  font-size: 120% !important;
}

.text-accent {
  color: $camp-emerald;
}

.bg-footer-theme {
  color: #8d9fb0;
}

tr.row-dark {
  background-color: #efefef;
}

@media screen {
  #layout-navbar div.navbar-nav div.nav-item.spaced {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media screen and (max-width: 1200px) {
  #layout-navbar div.navbar-nav div.nav-item.spaced {
    padding-left: 0;
    padding-right: 0;
  }
}

.comment-container {
  border: #E8E9E9 1px solid;
  background-color: white;
}

.user-avatar {
  background: $camp-navy;
  border-radius: 200px;
  width: 32px;
  height: 32px;
  color: white;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
  padding-top: 4px;
  display: inline-block;
  flex-shrink: 0;
}

.user-avatar-dark {
  @extend .user-avatar;
  background: $camp-neutral;
  color: $camp-navy;
}

.poll-choice-bar {
  border-radius: 8px;
  background-color: #c2dcfc;
}

.poll-label input {
  opacity: 0;
  width: 0;
  height: 0;
  margin-left: 18px;
}

.poll-control {
  color: #000000;
  position: relative;
  background-color: #f9f9f9;
  width: 100%;
  padding: 8px 12px;
  margin-top: -23px;
}

.poll-control:hover {
  background-color: #c2dcfc;
}

input:checked + .poll-control {
  background-color: #c2dcfc;
}

.poll-icon {
  color: #c3cbd5;
}

.poll-control:hover .poll-icon:before {
  color: #999;
  font-weight: 300;
}

input:checked + .poll-control .poll-icon:before {
  font-weight:900;
  color: #555;
}

.remove-choice-button {
  border-radius: 8px;
}

.poll-choice-input {
  border-bottom: 1px solid #e3e4e6;
}

.hover-user-avatar {
  background: $camp-neutral;
  border-radius: 90px;
  width: 90px;
  height: 90px;
  color: $camp-ink;
  font-size: 45px;
  text-align: center;
  vertical-align: middle;
  padding-top: 10px;
  display: inline-block;
  flex-shrink: 0;
}

.link-preview {
  padding-top: 6px;
  padding-left: 8px;
  border-left-color: #80808042;
  padding-bottom: 1px;
  border-left-style: solid;
  margin-top: 7px;
}

.link-preview-title {
  margin-top: 8px;
}

.link-preview-description {
  margin-top: 8px;
}

.hover-container {
  pointer-events: none;
  background-color: #f9f9f9;
}

.hover-container:hover {
  background-color: #f0f0f0;
  font-weight: 450;
}

.hover-child {
  pointer-events: auto;
}

.full-width {
  width: 100%;
}

@media (max-width: 576px) {
  .xs-img-middle {
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 5px;
  }
  .xs-responsive-text {
    font-size: 85%;
    font-weight: 400;
  }
  .d-xs-none {
    display: none !important;
  }
  .d-xs-block {
    display: block !important;
  }
}

@media (min-width: 769px) {
  .sm-user-card-height {
    min-height: 200px;
  }
}

.poll-input-padding {
  padding-top:34px;
}

@media (max-width: 768px) {
  .poll-input-padding {
    padding-top: 0;
  }
  .sm-pt-0 {
    padding-top: 0 !important;
  }
}

.min-w-40 {
  min-width: 40px;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.hover-hilite:hover {
  background-color: #f5faff;
}

.delete-button {
  right: 0px;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.507);
  z-index: 2;
}

// Make responsive embeds in certain areas have a max width of 750px to avoid taking up the whole screen
.embed-responsive.embed-responsive-16by9.not-full-width {
  max-width: 750px;
  margin: auto;
}

.ellipses-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-card {
  background-color: white;
  border-radius: 6px;
  min-height: 116px;
  position: relative;
}

// Collapse read forum topics in feed
#activity-feed .card {
  &[data-read=false] {
    border-color: #8897AA; // .border-secondary

    .feed-comment .badge.badge-danger {
      display: none;
    }
  }

  &[data-read=true] {
    .badge.badge-danger {
      display: none;
    }

    .feed-comment[data-read=false] {
      .badge.badge-danger {
        display: inline;
      }
    }
  }

  .feed-comment:not([data-read=false]) {
    .badge.badge-danger {
      display: none;
    }
  }
}

.readable-collapsed {
  max-height: 150px;
  overflow: hidden;
  position: relative;

  .readable-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, rgba(255,255,255,1) 100%);
  }
}

// Fix for Select2 on bootstrap modal
.select2-container--open {
  z-index: 9999999;
}
.default-style .select2-container--default .select2-selection {
  background-color: transparent;
  border-radius: 0;
}
.default-style fieldset > .select2-container--default .select2-selection {
  border: none;
}

/* Infinite scrolling */
.infinite-scroll {
  position: relative;

  .infinite-scroll-trigger {
    position: absolute;
    width: 100%;
    pointer-events: none;
    height: 1px;
    z-index: 1000000;
    left: 0;
    top: 85%;
  }

  .infinite-scroll-trigger {
    &[data-loading=false] + .infinite-scroll-loader {
      visibility: hidden;
    }
    &[data-loading=true] + .infinite-scroll-loader {
      visibility: visible;
    }
    &[data-done=true] + .infinite-scroll-loader {
      display: none !important;
    }
  }
}

/* Sticky sidebars */
.sidebar__main {
  will-change: min-height;
}

.sidebar__inner {
  transform: translate3d(0, 0, 0);
  will-change: position, transform;
}


.category-btn {
  border-color: #8897AA;
  background-color: #fff;
  color: #8897AA;
}
.category-btn:hover {
  border-color: transparent;
  color: #fff;
}
.category-pending:hover {
  background-color: #8897AA !important;
}
.category-approved:hover {
  background-color: #02BC77 !important;
}
.category-personal:hover {
  background-color:#f0ad4e !important;
}
.category-banned:hover {
  background-color: #d9534f !important;
}

.video-duration-overlay {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.75);
  color: white;
  padding: 0.1rem 0.5rem;
}

// Cap admin menu height and let it scroll vertically since it's too big
#navbarAdminDropdown .dropdown-menu {
  max-height: 75vh;
  overflow-y: auto;
}

// .column-vlist: container for displaying list of items that's ordered vertically with the option to split into 2 and 3 columns based on screen size
// For example: given a list of a, b, c, d, e, f, g
// 3 cols
//   a  d  g
//   b  e
//   c  f
//
// 2 cols
//   a  e
//   b  f
//   c  g
//   d

.column-vlist {
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 0.75rem;
  }

  @media (min-width: 992px) {
    column-count: 3;
    column-gap: 0.75rem;
  }
}

.two-column-vlist {
  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 0.75rem;
  }
}

[data-toggle=collapse][aria-expanded=true] {
  .fa-angle-down {
    display: none;
  }
}

[data-toggle=collapse][aria-expanded=false] {
  .fa-angle-up {
    display: none;
  }
}

fieldset {
  padding: 0.4em;
  legend {
    font-size: 1.1rem;
    font-weight: 500;
    display: inline;
    width: auto;
    padding: 5px;
  }
}

.text-black {
  color: $camp-text;
}

.forum-topic-input {
  border-style: none none solid none;
}

.btn-theme-light {
  color: $camp-text !important;
}

.btn-theme-light:hover {
  color: #FFF !important;
}

.forum-selector {
  border: none;
  text-decoration: underline;
  font-size: 1.6rem;
  margin-top: -0.3rem;
  font-weight: 800;
  max-width: 100%;
  text-overflow: ellipsis;
}

.forum-topic-comment-input {
  border-bottom: solid 1px;
  border-color: $camp-text;
}

.votes-number-small {
  margin-left: 2px;
  color: #777;
  font-size: 0.8rem;
}

.votes-number {
  color: $camp-emerald;
}

abbr[title=required] {
  text-decoration: none; //remove dotted line on required indicator
  color: $camp-gold;
  font-weight: bold;
}

.bookmark-icon {
  color: $camp-neutral;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $camp-text;
}

.bookmark-icon-active {
  @extend .bookmark-icon;
  color: $camp-gold;
}
