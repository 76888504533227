:root {
  --aa-icon-color-rgb: map-get($colors, bg);
  --aa-primary-color-rgb: map-get($colors, bg);
}

@import "@algolia/autocomplete-theme-classic/dist/theme.css";

.aa-Panel {
  z-index: 2147483647;
}

.aa-Autocomplete {
  .aa-Form {
    --aa-input-padding-val: calc(var(--aa-spacing) * 0.8333333333 - 0.5px);
    padding-left: var(--aa-input-padding-val);
    padding-right: calc(var(--aa-icon-size) + var(--aa-input-padding-val) * 2);

    background: $camp-ink;
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-color: $text-lightest;
  }

  .aa-Input {
    color: white;
    &::placeholder {
      color: $text-lightest;
    }
  }

  .aa-InputWrapperPrefix {
    display: none;
  }

  .aa-InputWrapperSuffix {
    position: absolute;
    right: 0;
  }

  .aa-Label {
    margin-bottom: 0;
  }

  .aa-ClearButton {
    color: white;
  }
}

#full-algolia-results {
  --aa-panel-max-height: none;
  --aa-panel-shadow: none;
  --aa-background-color-alpha: 0;
  --aa-selected-color-alpha: 0;

  .aa-Panel {
    position: unset !important;
  }

  .aa-Item {
    cursor: unset;
    pointer-events: none;
    padding-top: var(--aa-spacing);
    padding-bottom: var(--aa-spacing);

    a, button {
      pointer-events: all;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $divider-color;
    }
  }
}

.hit-title {
  line-height: 1.5;
  display: flex;
  align-items: center;

  .hit-icon {
    margin-right: 0.25rem;
    text-align: center;
    flex-shrink: 0;
    opacity: 0.7;
    font-size: 1.2rem;
  }
}

.hit-description {
  line-height: 1.5;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
