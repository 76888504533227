// ----------- IBM Plex Sans -----------
@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/IBMPlexSans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/IBMPlexSans-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/IBMPlexSans-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/IBMPlexSans-SemiBold.ttf) format("truetype");
}

// ----------- Source Sans Pro -----------
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSans3-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/SourceSans3-Italic.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/SourceSans3-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(../fonts/SourceSans3-SemiBold.ttf) format("truetype");
}

// Uncomment if we need the light font
//
//@font-face {
//  font-family: "Source Sans Pro";
//  font-style: normal;
//  font-weight: 300;
//  font-display: swap;
//  src: url(../fonts/SourceSans3-Light.woff) format("woff"),url(../fonts/SourceSans3-Light.ttf) format("truetype");
//}
//
//@font-face {
//  font-family: "Source Sans Pro";
//  font-style: italic;
//  font-weight: 300;
//  font-display: swap;
//  src: url(../fonts/SourceSans3-LightItalic.woff) format("woff"),url(../fonts/SourceSans3-LightItalic.ttf) format("truetype");
//}
