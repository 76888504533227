@import 'dropdown';
@import 'nav';
@import 'list-group';
@import 'modal';
//@import 'alert';

.breadcrumb-item + .breadcrumb-item::before {
  color: #a3a4a6;
  content: "\203A";
}
