// Using list-group-item for selecting radio / checkbox without javascript
// https://codepen.io/lehonti/pen/OzoXVa

.list-group-item {
  user-select: none;
}

.list-group input[type="checkbox"] {
  display: none;
}

.list-group input[type="checkbox"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="checkbox"] + .list-group-item:before {
  font-family: "Font Awesome 6 Free";
  content: "\f14a";
  color: transparent;
  font-weight: 900;
  margin-right: 1em;
}

.list-group input[type="checkbox"]:checked + .list-group-item {
  background-color: lighten($camp-ink, 85%);
  color: $camp-ink;
}

.list-group input[type="checkbox"]:checked + .list-group-item:before {
  color: inherit;
}

.list-group input[type="radio"] {
  display: none;
}

.list-group input[type="radio"] + .list-group-item {
  cursor: pointer;
}

.list-group input[type="radio"] + .list-group-item:before {
  font-family: "Font Awesome 6 Free";
  content: "\f192";
  color: transparent;
  font-weight: 900;
  margin-right: 1em;
}

.list-group input[type="radio"]:checked + .list-group-item {
  background-color: lighten($camp-ink, 85%);
  color: $camp-ink;
}

.list-group input[type="radio"]:checked + .list-group-item:before {
  color: inherit;
}
