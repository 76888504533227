#forum-tag-list {
  &.nav-pills .nav-link.active, &.nav-pills .nav-link.active:hover, &.nav-pills .nav-link.active:focus {
    background-color: #f5faff;
  }
}

#event-category-tags {
  &.nav-pills .nav-link {
    font-size: 0.8rem;
  }
}